<template>
<section class='poma_m'>
   <div class="banner">
      <a :href="slagerUrl" :class="{'en_a':$i18n.locale == 'en'}">
         <span>{{ $t('PC.Product.Slager.Price.shi-yong-slager') }}</span>
      </a>
      <img class="bg" :src="$i18n.locale=='en'?en_banner:zh_banner" alt="">
   </div>
   <div class="big_contain">
      <div class="pm_box pb1">
         <div class="box_top">
            <p class="title">{{ $t('PC.Product.Slager.Price.mian-fei-ban') }}</p>
            <p class="set_price"><i>￥</i>0<i>{{ $t('PC.Product.Slager.Price.yue') }}</i></p>
            <p class="subtitle">{{ $t('PC.Product.Slager.Price.xian-shi-mian-fei-shi-yong') }}</p>
            <div class="price_tag">{{ $t('PC.Product.Slager.Price.xian-shi-mian-fei') }}</div>
         </div>
         <div class="box_bottom">
            <p>{{ $t('PC.Product.Slager.Price.zui-da-ge-xiang-mu-ge-shu') }} <i>7</i></p>
            <p>{{ $t('PC.Product.Slager.Price.mo-ban-ku-mo-ban-mian-fei-shi-yong') }} <i class="iconfont iconslagervip_duihao"></i></p>
            <p>{{ $t('PC.Product.Slager.Price.gong-shi-shi-bie') }} <i class="iconfont iconslagervip_wrong"></i></p>
            <p>Word 2 LaTeX <i class="iconfont iconslagervip_wrong"></i></p>
            <p>{{ $t('PC.Product.Slager.Price.you-xian-ji-shu-zhi-chi') }} <i class="iconfont iconslagervip_wrong"></i></p>
         </div>
      </div>
      <div class="pm_box pb2">
         <div class="box_top">
            <p class="title">{{ $t('PC.Product.Slager.Price.fu-fei-ban') }}</p>
            <p class="set_price"><i>￥</i>26<i>{{ $t('PC.Product.Slager.Price.yue') }}</i></p>
            <p class="subtitle">{{ $t('PC.Product.Slager.Price.xian-zai-gou-mai-fu-fei-ban') }} <br /> {{ $t('PC.Product.Slager.Price.nian-fu-ke-xiang-shou-xian-shi-qi-liu-zhe-you-hui') }}</p>
            <div class="price_tag">{{ $t('PC.Product.Slager.Price.nian-fu-qi-liu-zhe') }}</div>
         </div>
         <div class="box_bottom">
            <p>{{ $t('PC.Product.Slager.Price.zui-da-ge-xiang-mu-ge-shu') }} <i class="iconfont iconslagervip_wuxian"> {{ $t('PC.Product.Slager.Price.wu-xian') }}</i></p>
            <p>{{ $t('PC.Product.Slager.Price.mo-ban-ku-mo-ban-mian-fei-shi-yong') }} <i class="iconfont iconslagervip_duihao"></i></p>
            <p>{{ $t('PC.Product.Slager.Price.gong-shi-shi-bie') }} <i class="iconfont iconslagervip_duihao"></i></p>
            <p>Word 2 LaTeX <i class="iconfont iconslagervip_duihao"></i></p>
            <p>{{ $t('PC.Product.Slager.Price.you-xian-ji-shu-zhi-chi') }} <i class="iconfont iconslagervip_duihao"></i></p>
         </div>
      </div>
   </div>
   <FooterB></FooterB>
</section>
</template>

<script>
import FooterB from "./components/FooterB";
export default {
   components: {
      FooterB,
   },
   data() {
      return {
         slagerUrl: Global.SLAGER_URL,
         en_banner: require("../../assets/en/images-mobile/banner_mb.jpg"),
         zh_banner: require("../../assets/zh/images-mobile/banner_mb.jpg"),
      }
   },
};
</script>

<style lang="scss" scoped>
.poma_m {
   margin-top: 48px;

   .banner {
      position: relative;

      a {
         position: absolute;
         top: 84px;
         left: 30px;
         width: 60px;
         height: 19px;
         border: 1px solid #fff;

         span {
            color: #fff;
            display: inline-block;
            width: 100%;
            font-family:   PingFangSC-Semibold, PingFang SC;
            text-align: center;
            line-height: 18px;
            vertical-align: top;
            font-size: 8px;
            transform: scale(.7);
            transform-origin: center center;
         }
      }

      .en_a {
         top: 94px;
      }

      img.bg {
         width: 100%;
         height: 150px;
      }
   }

   .big_contain {
      box-sizing: border-box;
      padding: 16px 20px;

      &>div {
         width: 335px;
         background: #FFFFFF;
         border-radius: 5px;
         border: 1px solid #E1E1E1;
         margin-bottom: 16px;

         div.setmeal {
            height: 53px;
            background-image: url("../../assets/zh/images-mobile/dingjia_img1@2x.png");
            background-size: contain;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
               font-size: 16px;
               color: #FFF;
               line-height: 22px;

            }
         }

         div.limit {
            border-bottom: 1px solid #E1E1E1;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 16px 20px;

            span {
               width: 133px;
               display: flex;
               justify-content: space-between;
            }
         }

         div.set_price {
            font-size: 30px;
            font-weight: 500;
            color: #333;
            line-height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 16px 0;

            i {
               font-size: 10px;
               font-family:   PingFangSC-Regular, PingFang SC;
               color: #212121;
               line-height: 14px;
               // transform-origin: center;
               // transform: scale(.8);
            }
         }
      }

      .pm_box {
         width: 335px;
         background: #FFFFFF;
         box-shadow: 0px 2px 28px 1px #DEDFE6;
         border-radius: 8px;
         overflow: hidden;
         color: #3D4654;

         .box_top {
            height: 129px;
            background-repeat: no-repeat;
            background-size: 110%;
            background-position: center center;
            position: relative;

            .title {
               padding-top: 20px;
               text-align: center;
               font-family: PingFangSC-Medium, PingFang SC;
               font-weight: 900;
               font-size: 16px;
               line-height: 22px;
            }

            .subtitle {
               text-align: center;
               font-size: 12px;
               font-family: PingFangSC-Regular, PingFang SC;
               line-height: 17px;
            }

            .set_price {
               font-size: 30px;
               line-height: 42px;
               text-align: center;
               padding: 4px 0;

               i {
                  height: 17px;
                  font-size: 12px;
                  font-family:   PingFangSC-Regular, PingFang SC;
                  line-height: 20px;
                  font-weight: normal;
               }

               .yue {
                  font-size: 14px;
               }
            }

            .price_tag {
               // width: 72px;
               // height: 27px;

               padding: 5px 8px;
               box-sizing: border-box;
               background: #F85B2D;
               border-radius: 8px 0px 8px 0px;
               font-size: 12px;
               font-family: PingFangSC-Semibold, PingFang SC;
               color: #FFFFFF;
               line-height: 17px;
               display: flex;
               align-items: center;
               justify-content: center;
               position: absolute;
               left: 0;
               top: 0;
            }
         }

         .box_bottom {
            padding: 20px;
            box-sizing: border-box;

            p {
               font-size: 12px;
               font-family: PingFangSC-Regular, PingFang SC;
               font-weight: 400;
               line-height: 17px;
               margin-bottom: 12px;
               display: flex;
               justify-content: space-between;
            }

         }
      }

      .pb1 {
         margin-right: 48px;

         .box_top {
            background-image: url("../../assets/images/dingjia_bg1.jpg");
         }

         .box_bottom {
            p {
               i {
                  width: 16px;
                  text-align: center;
               }
            }
         }

      }

      .pb2 {
         .box_top {
            color: #805424;
            background-image: url("../../assets/images/dingjia_bg2.jpg");
         }

         .box_bottom {
            p {
               i {
                  color: #B98C5E;
               }
            }
         }
      }
   }

}
</style>
